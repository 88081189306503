import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useEquipmentTypeAttributeContext from "./useEquipmentTypeAttributeContext";
import { Autocomplete } from "@mui/material";

function EquipmentTypeAttributeEditDialog() {
    const {
        equipmentTypeAttribute,
        selectedEquipmentType,
        handleChange,
        showDialog,
        setShowDialog,
        saveEquipmentTypeAttribute,
        hasError,
    } = useEquipmentTypeAttributeContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                saveEquipmentTypeAttribute();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title =
        equipmentTypeAttribute.id == null
            ? `Új elem felvitele ${selectedEquipmentType?.name} eszköztípushoz`
            : `Elem szerkesztése ${selectedEquipmentType?.name} eszköztípushoz`;

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={equipmentTypeAttribute.name}
                    name="name"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Mértékegység"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={equipmentTypeAttribute.unit}
                    name="unit"
                    required
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Alapértelmezett érték"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={equipmentTypeAttribute.defaultValue}
                    name="defaultValue"
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EquipmentTypeAttributeEditDialog;
