import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useEquipmentTypeAttributeContext from "./useEquipmentTypeAttributeContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.5 },
    { field: "unit", headerName: "Mértékegység", minWidth: 70, flex: 0.3 },
    {
        field: "defaultValue",
        headerName: "Alapért. érték",
        minWidth: 70,
        flex: 0.2,
    },
];

function EquipmentTypeAttributeTable() {
    const { equipmentTypeAttributes, setEditedId, showTableLoading } =
        useEquipmentTypeAttributeContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        rowSelected(id);
    }

    function rowSelected(id) {
        setEditedId(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        equipmentTypeAttributes,
        ["name"]
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                loading={showTableLoading}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default EquipmentTypeAttributeTable;
