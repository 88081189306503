import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Item } from "../dashboard/Dashboard";
import AdminCard from "./AdminCard";

function AdminPage() {
    return (
        <Grid mt={3} container spacing={3} rowSpacing={5} alignItems="stretch">
            <Grid item xs={4}>
                <Item>
                    <AdminCard />
                </Item>
            </Grid>
        </Grid>
    );
}

export default AdminPage;
