import KnowledgeBaseLinkPageContent from "./KnowledgeBaseLinkPageContent";
import { KnowledgeBaseLinkProvider } from "./KnowledgeBaseLinkContext";

function KnowledgeBaseLinkPage() {
    return (
        <KnowledgeBaseLinkProvider>
            <KnowledgeBaseLinkPageContent />
        </KnowledgeBaseLinkProvider>
    );
}

export default KnowledgeBaseLinkPage;
