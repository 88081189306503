import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useStatisticsApi } from "../../api/statisticsApi";
import { useEffect, useState } from "react";
import UserPerformanceCard from "./UserPerformanceCard";
import InfoDisplay from "./InfoCard";
import styled from "@emotion/styled";

const SectionHeader = styled(Typography)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2), // assuming the theme's spacing unit is 8px
    marginBottom: theme.spacing(2),
    textAlign: "center", // if you want to center the text
}));

const StatisticsCard = () => {
    const { getDashboardStatistics } = useStatisticsApi();
    const [statistics, setStatistics] = useState(null);

    useEffect(() => {
        fetchDashboardStatistics();
    }, []);

    function fetchDashboardStatistics() {
        getDashboardStatistics((response) => {
            setStatistics(response.data.statistics);
        });
    }

    function getUserPerformanceList(userPerformance) {
        return [
            {
                label: "Jegyzőkönyvek a héten",
                value: userPerformance.reportsThisWeek,
            },
            {
                label: "Jegyzőkönyvek a hónapban",
                value: userPerformance.reportsThisMonth,
            },
            {
                label: "Jegyzőkönyvezett eszközök a héten",
                value: userPerformance.reportEquipmentsThisWeek,
            },
            {
                label: "Jegyzőkönyvezett eszközök a hónapban",
                value: userPerformance.reportEquipmentsThisMonth,
            },
        ];
    }

    function getTotal(data) {
        const playground = data?.playground ?? 0;
        const sportEquipment = data?.sportEquipment ?? 0;
        const entertainment = data?.entertainment ?? 0;
        return playground + sportEquipment + entertainment;
    }

    return (
        <Box>
            <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                    backgroundColor: "primary.main", // using the theme's primary color
                    color: "primary.contrastText", // ensuring text is readable on the primary color
                    padding: "8px", // adding some padding
                    borderRadius: "4px", // optional rounded corners
                }}
            >
                Statisztika
            </Typography>
            {statistics != null ? (
                <Box>
                    <SectionHeader>
                        Személyes Teljesítmény Áttekintés
                    </SectionHeader>
                    <Box>
                        <Grid
                            container
                            spacing={3}
                            rowSpacing={3}
                            alignItems="stretch"
                        >
                            <Grid item xs={6}>
                                <InfoDisplay
                                    name="Jegyzőkönyvek a héten"
                                    data={
                                        statistics.currentUserStatistics
                                            .reportsThisWeek
                                    }
                                    total={(data) => getTotal(data)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoDisplay
                                    name="Jegyzőkönyvek a hónapban"
                                    data={
                                        statistics.currentUserStatistics
                                            .reportsThisMonth
                                    }
                                    total={(data) => getTotal(data)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoDisplay
                                    name="Jegyzőkönyvezett eszközök a héten"
                                    data={
                                        statistics.currentUserStatistics
                                            .reportEquipmentsThisWeek
                                    }
                                    total={(data) => getTotal(data)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InfoDisplay
                                    name="Jegyzőkönyvezett eszközök a hónapban"
                                    data={
                                        statistics.currentUserStatistics
                                            .reportEquipmentsThisMonth
                                    }
                                    total={(data) => getTotal(data)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <SectionHeader>
                            Adminisztratív Statisztikák
                        </SectionHeader>

                        <Grid
                            container
                            spacing={3}
                            rowSpacing={3}
                            alignItems="stretch"
                        >
                            <Grid key="mostReportInWeekFullName" item xs={6}>
                                <InfoDisplay
                                    name="Legtöbb jegyzőkönyv a héten"
                                    data={
                                        statistics.publicStatistics
                                            .mostReportInWeekFullName
                                    }
                                    total={(data) => data.total}
                                />
                            </Grid>
                            <Grid key="mostReportInMonthFullName" item xs={6}>
                                <InfoDisplay
                                    name="Legtöbb jegyzőkönyv a hónapban"
                                    data={
                                        statistics.publicStatistics
                                            .mostReportInMonthFullName
                                    }
                                    total={(data) => data.total}
                                />
                            </Grid>
                        </Grid>

                        {statistics.adminStatistics && (
                            <Grid>
                                <Grid
                                    sx={{ mt: 0 }}
                                    container
                                    spacing={3}
                                    rowSpacing={3}
                                    alignItems="stretch"
                                >
                                    <Grid key="reportsThisWeek" item xs={6}>
                                        <InfoDisplay
                                            name="Összes jegyzőkönyv a héten"
                                            data={
                                                statistics.adminStatistics
                                                    .reportsThisWeek
                                            }
                                            total={(data) => getTotal(data)}
                                        />
                                    </Grid>
                                    <Grid key="reportsThisMonth" item xs={6}>
                                        <InfoDisplay
                                            name="Összes jegyzőkönyv a hónapban"
                                            data={
                                                statistics.adminStatistics
                                                    .reportsThisMonth
                                            }
                                            total={(data) => getTotal(data)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    sx={{ mt: 0 }}
                                    container
                                    spacing={3}
                                    rowSpacing={3}
                                    alignItems="stretch"
                                >
                                    <Grid key="reportsTotal" item xs={6}>
                                        <InfoDisplay
                                            name="Összes jegyzőkönyv az évben"
                                            data={
                                                statistics.adminStatistics
                                                    .reportsThisYear
                                            }
                                            total={(data) => getTotal(data)}
                                        />
                                    </Grid>
                                    <Grid key="reportsTotal" item xs={6}>
                                        <InfoDisplay
                                            name="Összes jegyzőkönyv"
                                            data={
                                                statistics.adminStatistics
                                                    .reportsTotal
                                            }
                                            total={(data) => getTotal(data)}
                                        />
                                    </Grid>
                                    {statistics.adminStatistics.userPerformances.map(
                                        (userPerformance) => (
                                            <Grid
                                                key={userPerformance.email}
                                                item
                                                xs={6}
                                            >
                                                <UserPerformanceCard
                                                    name={
                                                        userPerformance.fullName
                                                    }
                                                    data={getUserPerformanceList(
                                                        userPerformance
                                                    )}
                                                />
                                            </Grid>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Box>
            ) : (
                <Box>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default StatisticsCard;
