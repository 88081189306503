import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import useAuth from "../../hooks/useAuth";

const KnowledgeBaseCard = () => {
    const { auth } = useAuth();
    return (
        <Box>
            <Typography
                variant="h5"
                gutterBottom
                component="div"
                sx={{
                    backgroundColor: "primary.main", // using the theme's primary color
                    color: "primary.contrastText", // ensuring text is readable on the primary color
                    padding: "8px", // adding some padding
                    borderRadius: "4px", // optional rounded corners
                }}
            >
                Tudástár
            </Typography>
            <List>
                <ListItem disablePadding>
                    <ListItemButton component="a" href="/knowledgebase/links">
                        <ListItemIcon>
                            <LightbulbIcon />
                        </ListItemIcon>
                        <ListItemText primary="Oktatási linkek" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );
};

export default KnowledgeBaseCard;
