// Updated EntertainmentReportEquipment Component
import { Typography, TextField, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useReportApi } from "../../../api/reportApi";
import useReportContext from "../useReportContext";
import { useNavigate } from "react-router-dom";

function ReportCommentPage() {
    const { saveComment } = useReportApi();
    const { report, reportConfig } = useReportContext();
    const navigate = useNavigate();

    const [comment, setComment] = useState("");

    useEffect(() => {
        if (comment == "") {
            setComment(
                report?.baseData.comment ?? reportConfig?.reportDefaultComment
            );
        }
    }, [reportConfig, report]);

    function handleButtonClick() {
        saveComment(report.baseData.id, comment, (response) => {
            navigate(-1);
        });
    }
    return (
        <div style={{ height: 500, width: "100%" }}>
            <TextField
                sx={{ mb: 2 }}
                label="Megjegyzés"
                multiline
                rows={10} // Number of visible rows
                placeholder="Megjegyzés"
                variant="outlined"
                fullWidth
                value={comment}
                onChange={(event) => setComment(event.target.value)}
            />
            <Button
                sx={{ flexGrow: 1 }}
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
            >
                Mentés
            </Button>
        </div>
    );
}

export default ReportCommentPage;
