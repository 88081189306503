import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { KNOWLEDGE_BASE_LINK_URL } from "./Url";

export function useKnowledgeBaseLinkApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getKnowledgeBaseLinks(onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(`${KNOWLEDGE_BASE_LINK_URL}/list`, {
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function saveKnowledgeBaseLink(data, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            const request = {
                name: data.name,
                link: data.link,
            };
            if (data.id === null) {
                await axiosPrivate
                    .post(
                        `${KNOWLEDGE_BASE_LINK_URL}`,
                        JSON.stringify(request),
                        {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                        }
                    )
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            } else {
                await axiosPrivate
                    .put(
                        `${KNOWLEDGE_BASE_LINK_URL}/${data.id}`,
                        JSON.stringify(request),
                        {
                            headers: { "Content-Type": "application/json" },
                            withCredentials: true,
                        }
                    )
                    .then((response) => {
                        if (typeof onSuccess === "function")
                            onSuccess(response);
                    });
            }
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function deleteKnowledgeBaseLink(id, onSuccess) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .delete(`${KNOWLEDGE_BASE_LINK_URL}/${id}`, {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                })
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }
    return {
        getKnowledgeBaseLinks,
        saveKnowledgeBaseLink,
        deleteKnowledgeBaseLink,
    };
}
