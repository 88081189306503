import { createContext, useState, useEffect } from "react";
import { useKnowledgeBaseLinkApi } from "../../../api/knowledgeBaseApi";

const emptyKnowledgeBaseLink = {
    id: null,
    name: null,
    link: null,
};

const KnowledgeBaseLinkContext = createContext({});

export const KnowledgeBaseLinkProvider = ({ children }) => {
    const {
        getKnowledgeBaseLinks,
        saveKnowledgeBaseLink,
        deleteKnowledgeBaseLink,
    } = useKnowledgeBaseLinkApi();

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [links, setLinks] = useState([]);
    const [data, setData] = useState({
        emptyKnowledgeBaseLink,
    });
    const hasError = !data.name || !data.link;
    const showTableEditButtons = data.id != null;

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchLinks();
    }, []);

    function setSelectedLink(id) {
        if (id != null) {
            const link = links.find((link) => link.id === id);
            setData(link);
        } else {
            setData(emptyKnowledgeBaseLink);
        }
    }

    function fetchLinks() {
        getKnowledgeBaseLinks((response) => {
            setLinks(response.data);
        });
    }

    function save() {
        saveKnowledgeBaseLink(data, (response) => {
            setData(emptyKnowledgeBaseLink);
            fetchLinks();
        });
    }

    function deleteLink() {
        deleteKnowledgeBaseLink(data.id, (response) => {
            fetchLinks();
        });
    }

    return (
        <KnowledgeBaseLinkContext.Provider
            value={{
                data,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                links,
                setLinks,
                hasError,
                handleChange,
                save,
                deleteLink,
                showTableEditButtons,
                setSelectedLink,
            }}
        >
            {children}
        </KnowledgeBaseLinkContext.Provider>
    );
};

export default KnowledgeBaseLinkContext;
