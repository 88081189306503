import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useEquipmentContext from "./useEquipmentContext";
import { useEquipmentApi } from "../../../api/equipmentApi";
import EquipmentEditDialogContent, {
    emptyEquipment,
} from "./EquipmentEditDialogContent";
import { useEffect, useState } from "react";
import { ManufacturerProvider } from "../manufacturer/ManufacturerContext";

function EquipmentEditDialog({
    equipmentId,
    manufacturer,
    equipmentCategory,
    onClose,
}) {
    const { getEquipment, saveEquipment } = useEquipmentApi();
    const { showEquipmentEditDialog, setShowEquipmentEditDialog } =
        useEquipmentContext();

    const [hasError, setHasError] = useState(false);
    const [equipment, setEquipment] = useState();

    useEffect(() => {
        if (equipmentId != null) {
            getEquipment(equipmentId, (response) => {
                setEquipment(response.data.equipment);
            });
        } else {
            setEquipment(emptyEquipment);
        }
    }, [equipmentId]);

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                saveEquipment(equipment, (response) => {
                    onClose(response);
                });
                break;
            }
            default:
                break;
        }
        setShowEquipmentEditDialog(false);
    };

    const title =
        equipment && equipment.id == null ? "Új eszköz" : "Eszköz szerkesztés";

    if (equipment) {
        return (
            <ManufacturerProvider>
                <Dialog
                    disableEscapeKeyDown
                    fullWidth
                    maxWidth="md"
                    open={showEquipmentEditDialog}
                    onClose={handleClose}
                >
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>
                        <EquipmentEditDialogContent
                            equipment={equipment}
                            manufacturer={manufacturer}
                            showNewManufacturerButton={false}
                            equipmentCategory={equipmentCategory}
                            onDataChanged={(hasError, equipment) => {
                                setHasError(hasError);
                                setEquipment(equipment);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button name="cancel" onClick={handleClose}>
                            Mégse
                        </Button>
                        <Button
                            name="save"
                            disabled={hasError}
                            onClick={handleClose}
                        >
                            Mentés
                        </Button>
                    </DialogActions>
                </Dialog>
            </ManufacturerProvider>
        );
    }
}

export default EquipmentEditDialog;
