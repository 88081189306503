import { createContext, useEffect, useState } from "react";
import { useEquipmentApi } from "../../../../api/equipmentApi";
import { useEquipmentGroupApi } from "../../../../api/equipmentGroupApi";
import { useManufacturerApi } from "../../../../api/manufacturerApi";
import useReportContext from "../../useReportContext";

const PlaygroundReportEquipmentsByManufacturerContext = createContext({});

export const PlaygroundReportEquipmentsByManufacturerProvider = ({
    children,
}) => {
    const { reportConfig } = useReportContext();
    const { getEquipmentsByManufacturerAndCategory } = useEquipmentApi();
    const { getManufacturers } = useManufacturerApi();
    const { getEquipmentGroupsByEquipmentCategory } = useEquipmentGroupApi();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [selectedManufacturer, setSelectedManufacturer] = useState(null);
    const [manufacturers, setManufacturers] = useState([]);
    const [equipmentGroups, setEquipmentGroups] = useState([]);
    const [editedId, setEditedId] = useState(null);

    const [equipments, setEquipments] = useState([]);

    useEffect(() => {
        fetchManufacturers();
        fetchEquipmentGroups();
    }, []);

    useEffect(() => {
        if (selectedManufacturer) {
            fetchEquipmentsByManufacturer(selectedManufacturer);
        }
    }, [selectedManufacturer, setSelectedManufacturer]);

    function fetchManufacturers() {
        return new Promise((resolve, reject) => {
            getManufacturers((response) => {
                if (response) {
                    setManufacturers(response.data.manufacturers);
                    resolve(response.data.manufacturers);
                } else {
                    reject(new Error("Failed to fetch manufacturers"));
                }
            });
        });
    }

    function fetchEquipmentGroups() {
        getEquipmentGroupsByEquipmentCategory(
            reportConfig.equipmentCategory.id,
            (response) => {
                setEquipmentGroups(response.data.equipmentGroups);
            }
        );
    }

    function fetchEquipmentsByManufacturer(manufacturer) {
        getEquipmentsByManufacturerAndCategory(
            manufacturer.id,
            reportConfig.equipmentCategory.id,
            (response) => {
                setEquipments(response.data.equipments);
            }
        );
    }

    const addEquipmentsFromEquipmentGroup = async (equipments) => {
        setEquipments(equipments);
    };

    return (
        <PlaygroundReportEquipmentsByManufacturerContext.Provider
            value={{
                manufacturers,
                fetchManufacturers,
                equipmentGroups,
                showDeleteConfirm,
                setShowDeleteConfirm,
                equipments,
                setEquipments,
                editedId,
                setEditedId,
                selectedManufacturer,
                setSelectedManufacturer,
                fetchEquipmentsByManufacturer,
                addEquipmentsFromEquipmentGroup,
            }}
        >
            {children}
        </PlaygroundReportEquipmentsByManufacturerContext.Provider>
    );
};

export default PlaygroundReportEquipmentsByManufacturerContext;
