import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useEquipmentTypeContext from "./useEquipmentTypeContext";
import {
    Autocomplete,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";

function EquipmentCategoryEditDialog() {
    const {
        equipmentCategories,
        data,
        handleChange,
        showDialog,
        setShowDialog,
        save,
        errors,
        hasError,
    } = useEquipmentTypeContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title =
        data.id == null ? "Új eszköz típus" : "Eszköz típus szerkesztés";

    return (
        <Dialog
            disableEscapeKeyDown
            fullWidth
            maxWidth="md"
            open={showDialog}
            onClose={handleClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic name"
                    label="Név"
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.name}
                    error={errors.name != null}
                    helperText={errors.name}
                    name="name"
                    required
                />
                <Autocomplete
                    sx={{ width: "100%", my: 1 }}
                    id="free-solo-demo"
                    name="categoryName"
                    value={data.category}
                    onChange={(event, newValue) => {
                        if (event != null) {
                            event.target.type = "autocomplete";
                            event.target.name = "category";
                            event.target.selectedOption = newValue;
                            handleChange(event);
                        }
                    }}
                    options={equipmentCategories}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} label="Eszköz kategória" />
                    )}
                />
                <TextField
                    sx={{ width: "100%", my: 1 }}
                    id="outlined-basic email"
                    label="Megjegyzés"
                    multiline
                    variant="outlined"
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={data.description}
                    error={errors.description != null}
                    helperText={errors.description}
                    name="description"
                />
                {data.category?.id == 3 && (
                    <FormControl fullWidth sx={{ width: "100%", my: 1 }}>
                        <InputLabel id="demo-simple-select-label">
                            Veszélyességi osztály
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="hazardClass"
                            label="Veszélyességi osztály"
                            onChange={handleChange}
                            value={data.hazardClass}
                        >
                            <MenuItem key="1" value="1">
                                1
                            </MenuItem>
                            <MenuItem key="2" value="2">
                                2
                            </MenuItem>
                            <MenuItem key="3" value="3">
                                3
                            </MenuItem>
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button name="cancel" onClick={handleClose}>
                    Mégse
                </Button>
                <Button name="save" disabled={hasError} onClick={handleClose}>
                    Mentés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EquipmentCategoryEditDialog;
