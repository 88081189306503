import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import { Label } from "@mui/icons-material";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

// Styled item value which is bold and primary color
const ItemValue = styled(Typography)(({ theme }) => ({
    fontWeight: "bold",
    color: theme.palette.primary.main,
    display: "inline",
    variant: "h6",
    style: { flexShrink: 0, whiteSpace: "nowrap" },
}));

// A functional component that takes name and value as props
const InfoDisplay = ({ name, data, total }) => {
    return (
        <Paper
            elevation={3}
            style={{
                padding: "16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Játszótér</TableCell>
                            <TableCell align="left">Fitnesz</TableCell>
                            <TableCell align="left">Szórakoztató</TableCell>
                            <TableCell align="left">Összesen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell
                                align="left"
                                sx={{ borderBottom: "none" }}
                            >
                                {name}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{ borderBottom: "none" }}
                            >
                                {data?.playground ?? 0}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{ borderBottom: "none" }}
                            >
                                {data?.sportEquipment ?? 0}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{ borderBottom: "none" }}
                            >
                                {data?.entertainment ?? 0}
                            </TableCell>
                            <TableCell
                                align="left"
                                sx={{ borderBottom: "none" }}
                            >
                                {total(data)}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default InfoDisplay;
