import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";
import useKnowledgeBaseLinkContext from "./useKnowledgeBaseLinkContext";

const columns = [
    { field: "name", headerName: "Név", minWidth: 70, flex: 0.5 },
    {
        field: "link",
        headerName: "Link",
        flex: 0.5,
    },
];

// Function to get a field's value for filtering
const getFieldValue = (row, field) => {
    return row[field]; // Default direct field access
};

function KnowledgeBaseLinkTable() {
    const { links, setSelectedLink } = useKnowledgeBaseLinkContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedLink(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        links,
        ["name", "link"],
        getFieldValue
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default KnowledgeBaseLinkTable;
