// Updated EntertainmentReportEquipment Component
import { Select, MenuItem, Typography, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import usePlaygroundReportEquipmentsByManufacturerContext from "../equipmentsByManufacturer/usePlaygroundReportEquipmentsContext";
import PrevNextButtons from "../../PrevNextButtons";
import useReportContext from "../../useReportContext";
import usePlaygroundReportEquipmentsContext from "../usePlaygroundReportEquipmentsContext";
import EquipmentEditDialogContent, {
    emptyEquipment,
} from "../../../basicdata/equipment/EquipmentEditDialogContent";
import { useEquipmentApi } from "../../../../api/equipmentApi";

function EntertainmentReportEquipment() {
    const { saveEquipment } = useEquipmentApi();
    const { selectedManufacturer, setSelectedManufacturer } =
        usePlaygroundReportEquipmentsByManufacturerContext();
    const {
        reportEquipments,
        saveReportEquipments,
        inspectionTypes,
        reportEquipmentsIsLoading,
    } = usePlaygroundReportEquipmentsContext();
    const { reportConfig, setNextEnabled } = useReportContext();

    const [inspectionType, setInspectionType] = useState(null);
    const [comment, setComment] = useState("");

    const [data, setData] = useState();
    const [hasError, setHasError] = useState(false);

    setNextEnabled(!hasError);

    useEffect(() => {
        if (reportEquipmentsIsLoading) return;
        if (reportEquipments?.length === 1) {
            const singleItem = reportEquipments[0];
            setInspectionType(singleItem.inspectionType || null);
            setData(singleItem.equipment);
            setComment(singleItem.comment);

            if (selectedManufacturer == null) {
                setSelectedManufacturer(
                    reportEquipments[0]?.equipment?.manufacturer
                );
            }
        } else {
            setData(emptyEquipment);
        }
    }, [reportEquipments]);

    return (
        <div style={{ height: 500, width: "100%" }}>
            <Typography variant="h5">Eszköz adatai</Typography>
            {data != null && (
                <EquipmentEditDialogContent
                    equipment={data}
                    showNewManufacturerButton={true}
                    equipmentCategory={reportConfig.equipmentCategory}
                    onDataChanged={(hasError, equipment) => {
                        setHasError(hasError);
                        if (
                            data?.type?.id != equipment?.type?.id &&
                            equipment?.type?.id == 40 &&
                            !comment
                        ) {
                            setComment(reportConfig.bouncyCastleDefaultComment);
                        }

                        setData(equipment);
                        if (inspectionType == null) {
                            setInspectionType(inspectionTypes[0]);
                        }
                    }}
                />
            )}
            <Stack
                direction="row"
                spacing={2}
                mt={2}
                justifyContent="space-between"
                alignItems="flex-start" // Align items to their natural height
            >
                <Select
                    value={inspectionType}
                    onChange={(event) => {
                        setInspectionType(event.target.value);
                    }}
                    renderValue={(item) => item.name}
                    sx={{ minWidth: 300 }} // Optional: Set a specific width for the Select component
                >
                    {inspectionTypes.map((inspectionType) => (
                        <MenuItem
                            key={inspectionType.id}
                            value={inspectionType}
                        >
                            {inspectionType.name}
                        </MenuItem>
                    ))}
                </Select>
                <TextField
                    label="Megjegyzés"
                    multiline
                    rows={4} // Number of visible rows
                    placeholder="Megjegyzés"
                    variant="outlined"
                    fullWidth
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                />
            </Stack>
            <PrevNextButtons
                nextFunction={() => {
                    return new Promise((resolve, reject) => {
                        saveEquipment(data, (response) => {
                            if (response.status === 200) {
                                const reportEquipment =
                                    reportEquipments[0] ?? {};
                                reportEquipment.inspectionType = inspectionType;
                                reportEquipment.comment = comment;
                                reportEquipment.equipment =
                                    response.data.equipment;
                                saveReportEquipments([reportEquipment])
                                    .then(() => resolve(true))
                                    .catch(() => resolve(false));
                            } else {
                                resolve(false);
                            }
                        });
                    }).catch(() => false);
                }}
            />
        </div>
    );
}

export default EntertainmentReportEquipment;
