import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKnowledgeBaseLinkApi } from "../../api/knowledgeBaseApi";
import PageTitleWithBackButton from "../common/PageTitleWithBackButton";
import { Box, Typography } from "@mui/material";

function KnowledgeBaseLinks() {
    const navigate = useNavigate();
    const { getKnowledgeBaseLinks } = useKnowledgeBaseLinkApi();
    const [links, setLinks] = useState([]);

    useEffect(() => {
        fetchLinks();
    }, []);

    function fetchLinks() {
        getKnowledgeBaseLinks((response) => {
            setLinks(response.data);
        });
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Box sx={{ mb: 10 }}>
                <PageTitleWithBackButton title="Oktatási linkek" />
            </Box>
            <ul>
                {links.map((link) => (
                    <li key={link.id}>
                        <Typography
                            variant="h5"
                            component="a"
                            href={link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                textDecoration: "none",
                                color: "primary.main",
                            }}
                        >
                            {link.name}
                        </Typography>
                    </li>
                ))}
            </ul>
        </Box>
    );
}

export default KnowledgeBaseLinks;
