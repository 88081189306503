import React from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Styles for the container
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 600,
    textAlign: "left",
}));

// Styled label at the top
const Label = styled(Typography)({
    fontWeight: "bold",
    fontSize: "1.5em",
    marginBottom: "1em",
});

function getTotal(data) {
    const playground = data.value.playground ?? 0;
    const sportEquipment = data.value.sportEquipment ?? 0;
    const entertainment = data.value.entertainment ?? 0;
    return playground + sportEquipment + entertainment;
}

// The InfoPaper component
const UserPerformanceCard = ({ name, data }) => {
    return (
        data && (
            <StyledPaper elevation={3}>
                <Label component="h2">{name}</Label>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">Játszótér</TableCell>
                                <TableCell align="left">Fitnesz</TableCell>
                                <TableCell align="left">Szórakoztató</TableCell>
                                <TableCell align="left">Összesen</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((data) => (
                                <TableRow>
                                    <TableCell align="left">
                                        {data.label}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.value.playground ?? 0}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.value.sportEquipment ?? 0}
                                    </TableCell>
                                    <TableCell align="left">
                                        {data.value.entertainment ?? 0}
                                    </TableCell>
                                    <TableCell align="left">
                                        {getTotal(data)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledPaper>
        )
    );
};

export default UserPerformanceCard;
